import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import { Link } from 'components/Link/Link'
import { ParagraphHeading } from 'components/ParagraphHeading/ParagraphHeading'
import SEO from 'components/SEO/SEO'
import {
  COMPANY_OFFICIAL_ADDRESS,
  COMPANY_OFFICIAL_NAME,
  COMPANY_OFFICIAL_TAX_NUMBER_NIP,
  SITE_HOSTNAME,
} from 'constants/texts/company'
import { CONTACT_EMAIL_ADDRESS } from 'constants/texts/contact'
import { Text } from 'ui/common'

const PrivacyPolicy: React.FC = () => (
  <Layout>
    <SEO title="Polityka Prywatności" />
    <LayoutTextSection mt="xl">
      <h1>Polityka Prywatności</h1>
      <p>
        Polityka Prywatności opisuje{' '}
        <strong>
          zasady ochrony oraz przetwarzania przez nas danych osobowych
        </strong>{' '}
        i informacji na Twój temat.
      </p>
      <p>
        Polityka dotycząca plików cookies, czyli tzw. ciasteczek, dostępna jest
        w sekcji <Link to="/polityka-cookies">Polityka Cookies</Link>.
      </p>

      <ParagraphHeading>Informacje ogólne</ParagraphHeading>
      <ol>
        <li>
          Niniejsza polityka dotyczy danych osobowych zbieranych poprzez{' '}
          <strong>Serwis</strong> www, funkcjonujący pod adresem url:{' '}
          <span className="colored bold">{SITE_HOSTNAME}</span>, a także danych
          podanych z pominięciem Serwisu (np.&nbsp;telefonicznie).
        </li>
        <li>
          <strong>Operatorem Serwisu</strong> oraz{' '}
          <strong>Administratorem Danych Osobowych</strong> jest firma:
          <br />
          <p>
            <span className="colored bold">{COMPANY_OFFICIAL_NAME}</span>,
            prowadząca działalność pod adresem:{' '}
            <span className="colored bold">{COMPANY_OFFICIAL_ADDRESS}</span>, o
            nadanym numerze identyfikacji podatkowej (NIP):{' '}
            <span className="colored bold">
              {COMPANY_OFFICIAL_TAX_NUMBER_NIP}
            </span>
            .
          </p>
        </li>
        <li>
          Adres kontaktowy poczty elektronicznej Operatora:{' '}
          <span className="colored bold">{CONTACT_EMAIL_ADDRESS}</span>.
        </li>
        <li>
          Operator jest Administratorem Twoich danych osobowych w odniesieniu do
          danych podanych dobrowolnie w Serwisie.
        </li>
        <li>
          Serwis wykorzystuje dane osobowe w następujących celach:
          <ul>
            <li>Obsługa zapytań przez formularz,</li>
            <li>Przygotowanie, pakowanie, wysyłka towarów,</li>
            <li>Realizacja zamówionych usług,</li>
            <li>Windykacja należności,</li>
            <li>Prowadzenie newslettera i komunikacji emailowej,</li>
            <li>Prezentacja oferty lub informacji.</li>
          </ul>
        </li>
        <li>
          Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
          zachowaniu w następujący sposób:
          <ul>
            <li>
              Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
              wprowadzone do systemów Operatora.
            </li>
            <li>
              Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
              „ciasteczka”).
            </li>
          </ul>
          Danymi osobowymi są także dane przekazane do Glamo.pl z pominięciem
          Serwisu, np.&nbsp;telefonicznie lub e-mailem.
        </li>
      </ol>

      <ParagraphHeading>
        Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
      </ParagraphHeading>
      <ol>
        <li>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane
          osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
          zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
          Administratorze. Dotyczy to takich grup odbiorców:
          <ol type="a">
            <li>kurierzy,</li>
            <li>operatorzy pocztowi,</li>
            <li>kancelarie prawne i windykatorzy,</li>
            <li>operatorzy płatności,</li>
            <li>
              upoważnieni pracownicy i współpracownicy, którzy korzystają z
              danych w celu realizacji celu działania strony.
            </li>
          </ol>
        </li>
        <li>
          Twoje dane osobowe przetwarzane będą przez Administratora nie dłużej,
          niż jest to konieczne do wykonania związanych z nimi czynności
          określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W
          odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej
          niż przez 3 lata.
        </li>
        <li>
          Przysługuje Ci prawo żądania od Administratora:
          <ul>
            <li>dostępu do danych osobowych Ciebie dotyczących,</li>
            <li>ich sprostowania,</li>
            <li>usunięcia,</li>
            <li>ograniczenia przetwarzania,</li>
            <li>oraz do przeniesienia danych do wskazanego podmiotu.</li>
          </ul>
        </li>
        <li>
          Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania
          wskazanego w pkt 1.c. wobec przetwarzania danych osobowych w celu
          wykonania prawnie uzasadnionych interesów realizowanych przez
          Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
          będzie mogło być wykonane w przypadku istnienia ważnych prawnie
          uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub
          obrony roszczeń.
        </li>
        <li>
          Na działania Administratora przysługuje skarga do Prezesa Urzędu
          Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
        </li>
        <li>
          Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi
          Serwisu.
        </li>
        <li>
          W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
          świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
          przez Administratora marketingu bezpośredniego.
        </li>
        <li>
          Dane osobowe nie są przekazywane do krajów trzecich w rozumieniu
          przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy
          ich poza teren Unii Europejskiej.
        </li>
      </ol>

      <ParagraphHeading>
        Wybrane metody ochrony danych stosowane przez Operatora
      </ParagraphHeading>
      <ol>
        <li>
          Miejsca wprowadzania danych osobowych są chronione w warstwie
          transmisji (certyfikat SSL). Dzięki temu dane osobowe wprowadzone na
          stronie zostają zaszyfrowane w komputerze użytkownika i mogą być
          odczytane jedynie na docelowym serwerze.
        </li>
        <li>Operator okresowo zmienia swoje hasła administracyjne.</li>
        <li>
          Istotnym elementem ochrony danych jest regularna aktualizacja
          wszelkiego oprogramowania, wykorzystywanego przez Operatora do
          przetwarzania danych osobowych, co w szczególności oznacza regularne
          aktualizacje komponentów programistycznych.
        </li>
        <li>
          W odniesieniu do Danych Osobowych przechowywanych w formie papierowej
          (np.&nbsp;Umowy wypożyczeń) Operator upewnia się, żeby nie miały do
          nich dostępu osoby niepowołane.
        </li>
      </ol>

      <ParagraphHeading>Hosting</ParagraphHeading>
      <ol>
        <li>
          Serwis jest hostowany (technicznie utrzymywany) na serwerach
          operatorów Netlify oraz GitHub.
        </li>
      </ol>

      <ParagraphHeading>Informacje w formularzach</ParagraphHeading>
      <ol>
        <li>
          Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym
          dane osobowe, o ile zostaną one podane.
        </li>
        <li>
          Serwis może zapisać informacje o parametrach połączenia (oznaczenie
          czasu, adres IP).
        </li>
        <li>
          Serwis, w niektórych przypadkach, może zapisać informację ułatwiającą
          powiązanie danych w formularzu z adresem e-mail użytkownika
          wypełniającego formularz.
        </li>
        <li>
          Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza, np. w celu dokonania procesu obsługi
          zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp.
          Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do
          czego on służy.
        </li>
      </ol>

      <ParagraphHeading>Logi Administratora</ParagraphHeading>
      <ol>
        <li>
          Informacje o zachowaniu użytkowników w serwisie mogą podlegać
          logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.
        </li>
      </ol>

      <ParagraphHeading>Istotne techniki marketingowe</ParagraphHeading>
      <ol>
        <li>
          <p>
            Operator stosuje analizę statystyczną ruchu na stronie, poprzez
            serwisy Google Analytics oraz Google Tag Manager (Google LLC, część
            Alphabet Inc. z siedzibą w USA). Operator nie przekazuje do
            operatora tej usługi danych osobowych, a jedynie zanonimizowane
            informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
            końcowym użytkownika. W zakresie informacji o preferencjach
            użytkownika gromadzonych przez sieć reklamową Google użytkownik może
            przeglądać i edytować informacje wynikające z plików cookies przy
            pomocy narzędzia:{' '}
            <a href="https://www.google.com/ads/preferences/">
              https://www.google.com/ads/preferences/
            </a>
            .
          </p>
        </li>
      </ol>

      <ParagraphHeading>Informacja o plikach cookies</ParagraphHeading>
      <ol>
        <li>Serwis korzysta z plików cookies.</li>
        <li>
          Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
          szczególności pliki tekstowe, które przechowywane są w urządzeniu
          końcowym użytkownika Serwisu i przeznaczone są do korzystania ze stron
          internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
          internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
          końcowym oraz unikalny numer.
        </li>
        <li>
          Podmiotem zamieszczającym na urządzeniu końcowym użytkownika Serwisu
          pliki cookies oraz uzyskującym do nich dostęp jest Operator Serwisu.
        </li>
        <li>
          Pliki cookies wykorzystywane są w następujących celach:
          <ul>
            <li>
              ustawienia preferencji użytkownika dotyczących działania Serwisu,
            </li>
            <li>obsługa danych statystycznych,</li>
            <li>serwowanie usług multimedialnych,</li>
            <li>
              realizacji celów określonych powyżej w części &quot;Istotne
              techniki marketingowe&quot;.
            </li>
          </ul>
        </li>
        <li>
          Pliki cookies zamieszczane w urządzeniu końcowym użytkownika Serwisu
          wykorzystywane mogą być również przez współpracujące z Operatorem
          Serwisu podmioty, w szczególności dotyczy to firm: Google (Google LLC,
          część Alphabet Inc. z siedzibą w USA), Facebook (Facebook Inc. z
          siedzibą w USA).
        </li>
        <li>
          Oprogramowanie do przeglądania stron internetowych (przeglądarka
          internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
          cookies w urządzeniu końcowym użytkownika. Użytkownicy Serwisu mogą
          dokonać zmiany ustawień w tym zakresie.
          <br />
          Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe
          jest także automatyczne blokowanie plików cookies Szczegółowe
          informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki
          internetowej.
        </li>
        <li>
          Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
          funkcjonalności dostępne na stronach internetowych Serwisu.
        </li>
        <li>
          <p>
            Szczegółowa polityka plików cookies Glamo.pl, zawierająca w
            szczególności cele ich wykorzystywania, odnosząca się do danych
            zapisywanych w pamięci Urządzenia dostępowego do Serwisu dostępna
            jest pod adresem{' '}
            <Link to="/polityka-cookies">
              https://{SITE_HOSTNAME}/polityka-cookies
            </Link>
            .
          </p>
        </li>
      </ol>

      <ParagraphHeading>Zmiany w Polityce Prywatności</ParagraphHeading>
      <ol>
        <li>
          <p>
            Administrator zastrzega sobie prawo do zmiany niniejszej Polityki
            Prywatności, na którą mogą wpłynąć zmiany prawa w zakresie ochrony
            danych osobowych oraz rozwój naszego serwisu internetowego, bez
            konieczności informowania o tym użytkowników.
          </p>
        </li>
        <li>
          <p>
            Wprowadzone zmiany w Polityce Prywatności zawsze będą publikowane na
            tej stronie.
          </p>
        </li>
        <li>
          <p>
            Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki
            Prywatności.
          </p>
        </li>
      </ol>

      <ParagraphHeading>Informacje uzupełniające</ParagraphHeading>
      <ol>
        <li>
          W Serwisie mogą pojawiać się linki do innych stron internetowych.
          Takie strony internetowe działają niezależnie od Serwisu i nie są w
          żaden sposób nadzorowane przez Serwis {SITE_HOSTNAME}. Strony te mogą
          posiadać własne polityki dotyczące prywatności oraz regulaminy, z
          którymi zalecamy się zapoznać.
        </li>
        <li>
          W razie wątpliwości do któregokolwiek z zapisów niniejszej polityki
          prywatności jesteśmy do dyspozycji - nasze dane kontaktowe dostępne są
          pod adresem <Link to="/kontakt">https://{SITE_HOSTNAME}/kontakt</Link>
          .
        </li>
      </ol>

      <Text my="m">Aktualizacja: 10.01.2022r.</Text>
    </LayoutTextSection>
  </Layout>
)

export default PrivacyPolicy
